import React from "react";
import Modal from "react-bootstrap/modal";
import Button from "react-bootstrap/button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert"

import Loader from "./loader"


import { motion, AnimatePresence } from "framer-motion"

const Login = ({usercode,setUsercode, auth, setAuth, handleLogin, setMediaID}) => {


    return(
        <div className="login">
            <Modal.Dialog className="shadow" >
                {auth.loading ? <Loader width="75px" /> : null}
                <form action="/" onSubmit={(e) => {handleLogin(e, usercode, auth, setAuth, setMediaID)}}>
                <Modal.Header>
                    <Modal.Title>Authentifizierungscode</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <InputGroup size="lg">
                        <input type="text" className="form-control" onChange={(event) => {setUsercode({usercode: event.target.value})}} />
                    </InputGroup>
                 
                    <Form.Text muted className="" >
                        Bitte geben Sie Ihren Authentifizierungscode ein.
                    </Form.Text> 
                    <AnimatePresence initial={false}>
                        {auth.error &&
                            <motion.div layout="position"
                                initial={{ opacity: 0, height: "0vh", marginTop: "0rem" }}
                                animate={{ opacity: 1, height: "100%", marginTop: "1rem" }}
                                exit={{ opacity: 0 }} >
                                <Alert variant="primary" className="m-0">
                                    {auth.error}
                                </Alert>
                            </motion.div>
                        }
                    </AnimatePresence>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" type="submit" >Absenden</Button>
                </Modal.Footer>
                </form>
            </Modal.Dialog>
        </div>  
    )
}

export default Login;