import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

import Logo from '../static/vdw-logo_web.png';
import LogoMobile from '../static/vdw-logo_web_no_wm.png';

const Header = () => {
	return (
		<header className="header">
			<Navbar bg="light" expand="lg">
				<Navbar.Brand href="#home" className="text-primary">
					<span className="semibold ">Verbandstag</span> 2021
				</Navbar.Brand>
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="mr-auto"></Nav>
				</Navbar.Collapse>
				<a
					href="https://vdw-online.de/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img
						className="headerlogo d-none d-lg-block"
						src={Logo}
						alt="Logo VDW"
					/>
					<img
						className="headerlogo d-block d-lg-none m"
						src={LogoMobile}
						alt="Logo VDW"
					/>
				</a>
			</Navbar>
		</header>
	);
};

export default Header;
