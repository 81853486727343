import React, { useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import Loader from './loader';

const Player = ({ mediaID }) => {
	const [isloading, setIsloading] = useState(true);

	const VimeoInstance = () => (
		<Vimeo
			video={mediaID}
			color="fff"
			autoplay
			responsive
			dnt={true}
			onReady={() => {
				setIsloading(false);
			}}
			className={`${isloading && 'd-none '} shadow vimeoInstance`}
			showTitle={false}
		/>
	);

	return (
		<div className={`${isloading && 'loading'} vimeoplayer relative`}>
			<div className="aspect-ratio-box">
				<div className="aspect-ratio-box-inside">
					{isloading && <Loader width="100px" />}
					<VimeoInstance />
				</div>
			</div>
		</div>
	);
};

export default Player;
