import axios from 'axios';

const handleLogin = (event, usercode, auth, setAuth, setMediaID) => {
	event.preventDefault();

	setAuth({ ...auth, loading: true });

	if (!usercode.usercode) {
		//If no usercode is set
		setAuth({ ...auth, status: false, loading: false, error: false });
		return;
	}

	axios
		.get('/backend/auth.php?code=' + usercode.usercode)
		.then((res) => {
			const data = res.data;
			if (data.success === 'success') {
				setMediaID(data.mediaid);
				setAuth({ ...auth, status: true, loading: false });
			} else if (res.data.error) {
				setAuth({ ...auth, status: false, loading: false, error: data.error });
			}
		})
		.catch((err) => {
			console.log(err);
			setAuth({
				...auth,
				status: false,
				loading: false,
				error:
					'Der Authentifizierungs-Server konnte nicht erreicht werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut. Vielen Dank.',
			});
		});
};

export default handleLogin;
