import React from "react"

import {motion} from "framer-motion"

const isIE = /*@cc_on!@*/false || !!document.documentMode;

const loadingContainerVariants = {
    start: {
        scale: 0,
        transition: {   
            duration: .1,
        },
    },
    end: {
        scale: 1,
        transition: {
            staggerChildren: .05,
            duration: .5,
        },
    },
  }
  

  const loadingCircleVariants = {
    start: {
      y: "-2.5%",
    },
    end: {
      y: "2.5%",
    },
  }

  const loadingCircleTransition = {
    duration: .8,
    yoyo: Infinity,
    ease: "easeInOut",
    //repeatDelay: .3
  }

const Loader = ({width = "200px", position = null}) => {
    return(
        <div className="loader" >
            {isIE ? "Lade..." :
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66.03 76.39" style={{"width": width}}>
                <rect width="57.84" height="76.39" style={{ fill : "#b9b9b9" }}/>
                    <motion.g  
                        variants={loadingContainerVariants}
                        initial="start"
                        animate="end">
                        <motion.g 
                            variants={loadingCircleVariants}
                            transition={loadingCircleTransition}>
                            <polyline points="57.84 36.81 49.65 28.62 57.84 20.44 66.03 28.62" style={{fill: "#fff", fillRule: "evenodd"}}/>
                            <polyline points="57.84 53.01 57.84 36.81 66.02 28.62 66.02 44.81" style={{fill: "#dddddd" ,fillRule: "evenodd"}}/>
                        </motion.g>
                        <motion.g 
                            variants={loadingCircleVariants}
                            transition={loadingCircleTransition}>
                            <polyline points="41.13 20.63 32.94 12.44 41.13 4.26 49.31 12.45" style={{fill: "#fff", fillRule: "evenodd"}}/>
                            <polyline points="41.13 36.83 41.13 20.63 49.31 12.45 49.31 28.63" style={{fill: "#dddddd" ,fillRule: "evenodd"}}/>
                        </motion.g>
                        <motion.g 
                            variants={loadingCircleVariants}
                            transition={loadingCircleTransition}>
                            <polyline points="33.18 53.17 24.99 44.98 33.18 36.8 41.36 44.98" style={{fill: "#fff", fillRule: "evenodd"}}/>
                            <polyline points="33.18 69.37 33.18 53.17 41.36 44.98 41.36 61.17" style={{fill: "#dddddd" ,fillRule: "evenodd"}}/>
                        </motion.g>
                        <motion.g 
                            variants={loadingCircleVariants}
                            transition={loadingCircleTransition}>
                            <polyline points="16.47 36.99 8.28 28.81 16.47 20.62 24.65 28.81" style={{fill: "#fff", fillRule: "evenodd"}}/>
                            <polyline points="16.47 53.2 16.47 36.99 24.65 28.81 24.65 44.99" style={{fill: "#dddddd" ,fillRule: "evenodd"}}/>
                        </motion.g>
                    </motion.g>
                </svg>
            }
        </div>
    )
}

export default Loader; 