import React, { useState } from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import './App.scss';

//Components
import Login from './components/login';
import Header from './components/header';
import Player from './components/player';
import Description from './components/description';

//Functions
import handleLogin from './functions/handleLogin';

function App() {
	const [usercode, setUsercode] = useState({ usercode: null });

	const [auth, setAuth] = useState({
		status: false,
		error: false,
		loading: false,
	});

	const [mediaID, setMediaID] = useState(null);

	return (
		<div className={`${auth.status === true && 'nobg'} App`}>
			<Header />
			<div className={`content`}>
				<Container>
					{auth.status === false ? (
						<Login
							usercode={usercode}
							setUsercode={setUsercode}
							auth={auth}
							setAuth={setAuth}
							handleLogin={handleLogin}
							setMediaID={setMediaID}
						/>
					) : (
						<>
							<Row className="py-5">
								<Col>
									<span className="h2">
										Wiederholung des vdw-Verbandstag 2021
									</span>
								</Col>
							</Row>
							<Player mediaID={mediaID} />
							<Description />
						</>
					)}
					<Row className="pt-4">
						<Col className="text-muted text-center  pb-5 ">
							<Alert
								variant="light"
								className={`${auth.status === true && 'nobg'}  footer`}
							>
								Verband der Wohnungs- und Immobilienwirtschaft in Niedersachsen
								und Bremen e.V. | &#169; {new Date().getFullYear()}
							</Alert>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}

export default App;
