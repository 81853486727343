import React, { useContext } from 'react';
import {
	Row,
	Col,
	Card,
	Accordion,
	Table,
	useAccordionToggle,
	AccordionContext,
} from 'react-bootstrap';

const Desription = () => {
	function ContextAwareToggle({ children, eventKey, callback }) {
		const currentEventKey = useContext(AccordionContext);

		const decoratedOnClick = useAccordionToggle(
			eventKey,
			() => callback && callback(eventKey)
		);

		const isCurrentEventKey = currentEventKey === eventKey;

		return (
			<div onClick={decoratedOnClick}>
				<span className="h5">{children}</span>{' '}
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className={isCurrentEventKey ? 'open' : ''}
					viewBox="0 0 24 24"
					fill="black"
				>
					<path d="M0 0h24v24H0z" fill="none" />
					<path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
				</svg>
			</div>
		);
	}

	return (
		<div className="description pt-4">
			<Row className="py-5">
				<Col>
					<hr />
					<p>
						Der Verbandstag 2021 ist beendet. Die gesamte Aufzeichnung kann hier
						abgerufen werden.
					</p>
				</Col>
			</Row>
		</div>
	);
};

export default Desription;
